.stand-select-group {
  .ant-checkbox-wrapper {
    margin: 6px 0;
    width: 100%;
    display: flex;
    align-items: flex-start;

    .ant-checkbox {
      margin-top: 8px;
    }

    &:hover {
      .stand-info-label {
        background-color: #f0f5ff;
      }
    }
  }
}

.stand-info-label {
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
  width: 100%;
  margin-left: 8px;

  .stand-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
    color: #1f1f1f;
    line-height: 1.4;
  }

  .stand-details {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .ant-tag {
      margin-right: 0;
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 20px;
      height: 22px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}